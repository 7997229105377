<template>
	<div class="page page-agenda">
		<h2 class="is-title is-inverted is-simple">{{ title }}</h2>
		<div class="page-background is-short" :style="{ backgroundImage: backgroundUrl }"></div>
		<div class="container">
			<div class="agenda__detail">
				<div class="agenda__session__time">
					<div>
						<p>
							<span>{{ eventSession.start_time | timeFormat(eventSession.date) }} - {{ eventSession.end_time | timeFormat(eventSession.date) }}</span> CET
						</p>
						<small
							><span
								>{{ eventSession.start_time | localTimeDateFormat(eventSession.date) }} - {{ eventSession.end_time | localTimeFormat(eventSession.date) }}</span
							>
							Your local time</small
						>
					</div>
				</div>
				<h2>
					{{ eventSession.symposium_title }}
				</h2>
				<h3>{{ eventSession.symposium_subtitle }}</h3>

				<div v-if="!isLogged && hasIframe(eventSession)" class="agenda__status is-not-logged">
					<span class="icon"><inline-svg :src="require('../assets/lock.svg')" width="25"></inline-svg></span>
					<span>Please <a :href="authUrl">login</a> or <a :href="registerUrl">register</a> to access the content</span>
				</div>

				<div v-if="isLogged && hasIframe(eventSession)" class="agenda__iframe">
					<iframe :src="iframeUrl" allow="fullscreen" />
				</div>

				<VueMarkdown
					v-if="eventSession.symposium_description"
					:source="eventSession.symposium_description"
					class="agenda__description content"
					:prerender="prerender"
				/>

				<div class="agenda__detail__back">
					<router-link :to="{ name: 'IndustryProgramme' }">
						<span class="icon"><inline-svg :src="require('../assets/back.svg')" width="26"></inline-svg></span>
						<span>Back</span>
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import $http from '../utils/http';
	import VueMarkdown from 'vue-markdown-v2';
	import { prerender } from '../utils/markdown';
	import session from '../utils/session';
	import meta from '../utils/meta';
	import { AUTHURL, REGISTERURL } from '../consts';
	import moment from 'moment-timezone';

	export default {
		name: 'AgendaSession',
		components: { VueMarkdown },
		data() {
			return {
				eventSession: {},
				session: session.state,
				title: 'Event programme',
				subtitle: '',
				background: '',
				registerUrl: REGISTERURL,
				authUrl: AUTHURL,
			};
		},
		computed: {
			isLogged() {
				return this.session.loggedIn;
			},
			backgroundUrl() {
				return this.background && this.background.url ? `url('${this.$options.filters.imageUrl(this.background)}')` : null;
			},
			iframeUrl() {
				let token = session.generateToken();
				if (!this.eventSession) return false;
				if (!this.eventSession.iframe_url) return false;
				return this.eventSession.iframe_url.replace('{token}', token);
			},
		},
		beforeRouteEnter(to, from, next) {
			$http.all([$http.get('page-industry-programme'), $http.get(`/industry-programmes?url=${to.params.url}`)]).then(
				$http.spread((page, data) => {
					next(vm => {
						vm.setPage(page);
						vm.setData(data);
					});
				})
			);
		},
		beforeRouteUpdate(to, from, next) {
			$http.all([$http.get('page-industry-programme'), $http.get(`/industry-programmes?url=${to.params.url}`)]).then(
				$http.spread((page, data) => {
					this.setPage(page);
					this.setData(data);
					next();
				})
			);
		},
		methods: {
			setPage({ data }) {
				if (data.title) {
					this.title = data.title;
				}
				meta.setTitle(this.title);
				this.subtitle = data.subtitle;
				this.background = data.background;
			},
			setData(data) {
				if (data && data.data && data.data[0]) this.eventSession = data.data[0];
			},
			prerender: prerender,
			hasIframe({ date, end_time, iframe_url }) {
				if (iframe_url) {
					let target = moment.tz.guess();
					let dEnd = moment.tz(`${date} ${end_time}`, 'CET').add(1, 'h');
					let now = moment().tz(target);

					return now.isAfter(dEnd);
				} else {
					return false;
				}
			},
		},
	};
</script>
